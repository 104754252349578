import axios from 'axios'

import { alertError } from './swalAlert'
import { getToken, removeToken } from './token'
import { translateMessage } from './translateMessage'

const handleURL = (url) => {
  const urlArr = ['/otp/verify', '/otp/resend', '/forgot-password', '/reset']
  const data = urlArr.find((x) => url.includes(x))
  return data
}
const httpService = (token) => {
  const service = axios.create()

  service.interceptors.request.use(
    function (config) {
      let currentToken = token || getToken()
      if (handleURL(config.url)) {
        currentToken = window.localStorage.getItem('token')
      }
      if (currentToken) config.headers.Authorization = currentToken
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )

  service.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        const { config } = error.response
        if (!handleURL(config.url)) {
          removeToken()
          return (window.location.href = '/')
        }
      } else if (error.message && error.message === 'Network Error') {
        alertError('Connection lost, please check your internet!')
      } else if (error.response.config.responseType === 'blob') {
        if (error.response.status === 422) alertError('Error!, Belum ada transaksi...')
        else alertError('Error!, coba lagi ...')
      } else if (error.response) {
        const { config, data } = error.response
        const { messages, status_code } = data
        if (status_code === 422) {
          if (config.url.includes('/umrah-promo/umrah_id')) {
            return error.response
          }
          let err = `Error!, ${translateMessage(messages)}`
          if (config.url.includes('/umrah/id')) err = `Error!, Umroh ${translateMessage(messages)}`
          alertError(err)
        }
      }
      return Promise.reject(error)
    },
  )
  return service
}

export default httpService
