import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import ads from './ads/ads.reducer'
import listPendaftaranAgent from './agent/listPendaftaranAgent/listPendaftaranAgent.reducer'
import aqiqah from './aqiqah/aqiqah.reducer'
import aqiqahMaster from './aqiqahMaster/aqiqahMaster.reducer'
import masterPromo from './aqiqahMaster/masterPromo/masterPromo.reducer'
import article from './article/article.reducer'
import auth from './auth/auth.reducer'
import bank from './bank/bank.reducer'
import cityPolygon from './cityPolygon/cityPolygon.reducer'
import dzikir from './dzikir/dzikir.reducer'
import features from './features/features.reducer'
import feePayment from './feePayment/feePayment.reducer'
import file from './file/file.reducer'
import hadith from './hadith/hadith.reducer'
import header from './header/header.reducer'
import infaq from './infaq/infaq.reducer'
import kategoriInfaq from './kategoriInfaq/kategoriInfaq.reducer'
import kategoriSedekah from './kategoriSedekah/kategoriSedekah.reducer'
import kategoriWakaf from './kategoriWakaf/kategoriWakaf.reducer'
import listUserManagement from './listUserManagement/listUserManagement.reducer'
import masterData from './masterData/masterData.reducer'
import menuCategory from './menuCategory/menuCategory.reducer'
import nominal from './nominal/nominal.reducer'
import notification from './notification/notification.reducer'
import partner from './partner/partner.reducer'
import configIntro from './pelatihan/configIntro/configIntro.reducer'
import couseList from './pelatihan/course/course.reducer'
import listDaftarPelatihan from './pelatihan/listDaftarPelatihan/listDaftarPelatihan.reducer'
import masterPelatihan from './pelatihan/masterPelatihan/masterPelatihan.reducer'
import pelatihanCategory from './pelatihan/pelatihanCategory/pelatihanCategory.reducer'
import masterPorsiHaji from './porsiHaji/masterPorsiHaji/masterPorsiHaji.reducer'
import partnerPorsiHaji from './porsiHaji/partnerPorsiHaji/partnerPorsiHaji.reducer'
import pendaftaranPorsiHaji from './porsiHaji/pendaftaranPorsiHaji/pendaftaranPorsiHaji.reducer'
import pray from './pray/pray.reducer'
import prayCategory from './prayCategory/prayCategory.reducer'
import promo from './promo/promo.reducer'
import promoPartner from './promo/promoPartner/promoPartner.reducer'
import listDaftarPembelian from './qurban/listDaftarPembelian/listDaftarPembelian.reducer'
import configQurban from './qurban/masterQurban/configQurban.reducer'
import masterQurban from './qurban/masterQurban/masterQurban.reducer'
import qurbanCategory from './qurban/qurbanCategory/qurbanCategory.reducer'
import rekonsiliasi from './rekonsiliasi/rekonsiliasi.reducer'
import roleUserManagement from './roleUserManagement/roleUserManagement.reducer'
import configSantrimedia from './santrimedia/configSantrimedia/configSantrimedia.reducer'
import dataPatner from './santrimedia/dataPatner/dataPatner.reducer'
import kantorWilayah from './santrimedia/kantorWilayah/kantorWilayah.reducer'
import sedekah from './sedekah/sedekah.reducer'
import sedekahReport from './sedekah/sedekahReport.reducer'
import sidebar from './sidebar/sidebar.reducer'
import configIntroTabunganUmroh from './tabunganUmroh/configIntro/configIntroTabungan.reducer'
import listTabunganUmroh from './tabunganUmroh/listTabunganUmroh/listTabunganUmroh.reducer'
import masterTabunganUmroh from './tabunganUmroh/masterTabunganUmroh/masterTabunganUmroh.reducer'
import transaction from './transaction/transaction.reducer'
import kategoriUmroh from './umroh/kategoriUmroh/kategoriUmroh.reducer'
import umroh from './umroh/masterUmroh/umroh.reducer'
import pendaftaranUmroh from './umroh/pendaftaranUmroh/pendaftaranUmroh.reducer'
import user from './user/user.reducer'
import verse from './verse/verse.reducer'
import wakaf from './wakaf/wakaf.reducer'

export default combineReducers({
  form,
  sidebar,
  header,
  auth,
  hadith,
  verse,
  dzikir,
  file,
  article,
  pray,
  prayCategory,
  pelatihanCategory,
  masterPelatihan,
  listDaftarPelatihan,
  listDaftarPembelian,
  configIntroTabunganUmroh,
  masterQurban,
  configQurban,
  masterTabunganUmroh,
  listTabunganUmroh,
  ads,
  kantorWilayah,
  nominal,
  user,
  notification,
  partner,
  menuCategory,
  transaction,
  qurbanCategory,
  feePayment,
  configIntro,
  bank,
  kategoriWakaf,
  wakaf,
  rekonsiliasi,
  partnerPorsiHaji,
  masterPorsiHaji,
  pendaftaranPorsiHaji,
  features,
  kategoriInfaq,
  infaq,
  sedekah,
  kategoriSedekah,
  sedekahReport,
  roleUserManagement,
  listUserManagement,
  kategoriUmroh,
  umroh,
  masterData,
  pendaftaranUmroh,
  listPendaftaranAgent,
  dataPatner,
  configSantrimedia,
  couseList,
  aqiqah,
  promo,
  promoPartner,
  aqiqahMaster,
  masterPromo,
  cityPolygon,
})
